import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row, Alert } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { EversoulCharacter } from '../../../modules/es/common/components/eversoul-character';

const EsGuidesIntro: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_intro.jpg"
            alt="Introduction to the game"
          />
        </div>
        <div className="page-details">
          <h1>Eversoul Introduction to the game</h1>
          <h2>Basic information about Eversoul.</h2>
          <p>
            Last updated: <strong>05/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="About Eversoul" />
        <p>
          Eversoul is a <strong>idle gacha game</strong> developed by Nine Ark
          and published by Kakao Games. The development team is based in South
          Korea and Eversoul it's their first game, but Kakao Games is a well
          known publisher that you might know from Guardian Tales and World
          Flipper for example.
        </p>
        <h5>What's an idle game?</h5>
        <p>
          An idle game is a{' '}
          <strong>
            game that doesn't need constant player input to progress
          </strong>
          . While for the first week or so after installing the game, you will
          be busy progressing through the story, unlocking new game modes or
          functionalities and everything will feel like any other mobile RPG
          game, <strong>soon you will hit a wall</strong> - your characters
          won't be strong enough to tackle the stage. This is the moment when
          the idle game part kicks in. Since idle games don't have stamina and
          you can't go back to earlier stages to 'farm' there, the only thing
          you can do is <strong>close the game, relax and wait</strong>.
        </p>
        <p>
          And while you're waiting, the game will 'farm' resources for you - and
          when you open it next day you will be able to make your characters
          stronger and soon you will beat the wall. Rinse and repeat.
        </p>
        <p>
          Here's a few examples of other popular idle games: AFK Arena, NIKKE,
          Mythic Heroes, Idle Heroes.
        </p>
        <SectionHeader title="Common terms" />
        <p>
          Before we move to the guide itself, here are some basic common terms
          used in idle games like Eversoul.
        </p>
        <ul>
          <li>
            <strong>Soul</strong> - that's how characters you can use in this
            game are called.
          </li>
          <li>
            <strong>C</strong> - Common soul. Marked by a green background.
          </li>
          <li>
            <strong>R</strong> - Rare soul. Marked by a blue background.
          </li>
          <li>
            <strong>E</strong> - Epic soul. Marked by a purple background.
          </li>
          <li>
            <strong>+ (for example R+)</strong> - this means a sub-tier between
            the big tiers.
          </li>
          <li>
            <strong>Food / Fooder</strong> - souls that are used to upgrade
            other Souls.
          </li>
          <li>
            <strong>Buff</strong> - a positive effect that makes your Souls
            stronger by increasing their stats or giving them new abilities.
          </li>
          <li>
            <strong>Debuff</strong> - a negative effect that makes your Souls
            weaker. Basically the opposite of a buff.
          </li>
          <li>
            <strong>CC (Crowd Control)</strong> - an effect that makes a Soul
            stop fighting. For example, Stun and Sleep can be classified as CC
            effects.
          </li>
          <li>
            <strong>DoT (Damage over Time)</strong> - a negative effect that
            makes a Soul receive damage over its duration.
          </li>
          <li>
            <strong>Cleanse</strong> - an ability that can remove debuffs from
            ally Souls.
          </li>
          <li>
            <strong>Dispell</strong> - an ability that can remove buffs from
            enemy Souls.
          </li>
          <li>
            <strong>HoT (Heal over Time)</strong> – a positive effect that makes
            a Soul receive healing over its duration.
          </li>
          <li>
            <strong>Mana</strong> – Effects casting of skills, Higher Mana
            regeneration equates to faster skill casting and vice-versa.
          </li>
          <li>
            <strong>Speed</strong> – Effects how fast skills are rotated, higher
            the speed, faster the skill procs and vice versa.
          </li>
          <li>
            <strong>Type / Faction Damage</strong> – Additional damage inflicted
            by souls to certain factions.
          </li>
          <li>
            <strong>Damage Sharing</strong> – an effect where the damage
            incurred by teammates is instead channelled to the Soul with the
            effect by certain %.
          </li>
          <li>
            <strong>M.Res/P.Res</strong> – Magical Resistance/Physical
            Resistance, an effect that allows you to decrease Magical or
            Physical Damage by certain extent.
          </li>
          <li>
            <strong>Invulnerability</strong> – an effect that causes the
            targeted soul to become immune to any damage during its duration.
          </li>
          <li>
            <strong>Lifesteal</strong> – an ability that heals the soul by
            converting part of damage inflicted by the soul’s attack into % of
            HP.
          </li>
          <li>
            <strong>CC Immunity</strong> – an ability that allows soul to resist
            any Crowd Control effect inflicted on it.
          </li>
          <li>
            <strong>Anti Heal</strong> – an effect that reduces the healing
            received by soul by a certain %.
          </li>
          <li>
            <strong>Mana Steal</strong> – an effect that allows soul to reduce
            Mana of enemy soul while simultaneously recovering their own.
          </li>
          <li>
            <strong>Skill Activation</strong> – an ability that increases the
            chances of secondary effects in skills to be casted successfully.
          </li>
          <li>
            <strong>Pull</strong> – an ability that allows a soul to pull an
            enemy soul from backline to frontline.
          </li>
          <li>
            <strong>Awakened Units</strong> - A New type of unit introduced in
            May 2024, Awakened Units are Limited units that are Variants of
            existing characters in new Chaos faction and can only be pulled from
            their Rate Up Banner and Erika Alchemy unlike rest of souls.
          </li>
        </ul>
        <SectionHeader title="Classes" />
        <p>
          Characters (Souls) in Eversoul can be split based on various criteria:
          Classes, Factions and Rarity. Let's talk about Classes first.
        </p>
        <ul>
          <li>
            <strong>Caster</strong> - your typical mages. They hide in the
            backline while casting devastating spells toward the enemies and
            most of their damage usually comes from them,
          </li>
          <li>
            <strong>Defender</strong> - the frontline of your team. Their job is
            to stand there and take a beating, while keeping the backline of the
            team safe,
          </li>
          <li>
            <strong>Ranger</strong> - your pew pew characters. They sit in the
            backline alongside Casters, but while their skills are also deadly,
            their regular attacks actually can deal a lot of damage too,
          </li>
          <li>
            <strong>Striker</strong> - the agile frontliners. Compared to
            Defenders and Warriors, they have less bulk, but in exchange have
            higher damage output,
          </li>
          <li>
            <strong>Supporter</strong> - the heart of the team. Without healers
            or buffers your team won't be able to survive enemies' onslaught -
            their heals and buffs are really crucial. Just make sure to keep
            them safe!
          </li>
          <li>
            <strong>Warrior</strong> - a frontliner with teeth. While Defenders
            are all about bulk and Strikers about agility, Warriors sit in the
            middle trying to balance both sides.
          </li>
        </ul>
        <SectionHeader title="Souls" />
        <p>
          Characters (Souls) in Eversoul belong to one of 6 factions, these are
          the characters that excel from each faction.
        </p>
        <h5>Humans</h5>
        <p>
          The Human faction consists of characters that are pretty universal and
          can work in any team.{' '}
        </p>
        <p>Important characters from the faction:</p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="aki" enablePopover />
          <EversoulCharacter mode="icon" slug="naomi" enablePopover />
          <EversoulCharacter mode="icon" slug="beleth" enablePopover />
        </div>
        <ul>
          <li>
            <strong>Aki</strong> - an Assassin that, when decently invested in,
            can one-shot most of the characters in the game. Her skill will
            constantly reset with each kill until everyone on the enemy team is
            dead.
          </li>
          <li>
            <strong>Naomi</strong> - a Caster who primarily excels in Guild Raid
            and Event Raid content, Naomi is one of the best DPS around due to
            her unique kit that allows her to ramp up her ultimate to levels of
            a nuke by constantly using her main skills.
          </li>
          <li>
            <strong>Beleth</strong> - a healer support which is one of the core
            units of various human teams in PVE as well as raid content due to
            her great heals, team buffs, and mana battery abilities.
          </li>
        </ul>
        <h5>Beasts</h5>
        <p>
          The Beast faction is very heavy on melee-based characters. It includes
          the first set of paired characters in the game (Mica & Seeha), who
          have to be used in the same team to bring out their full potential.
          Like the Human faction, Beasts can often be used in mixed teams.
        </p>
        <p>Important characters from the faction:</p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="lute" enablePopover />
          <EversoulCharacter mode="icon" slug="honglan" enablePopover />
        </div>
        <ul>
          <li>
            <strong>Lute</strong> - a defender who excels in raid content as a
            main DPS, (yes, you saw it right). Lute, despite being a defender by
            role, is a great low-investment DPS unit used in multiple raids
            thanks to her ultimate skill combined with the Defense Treasure.
          </li>
          <li>
            <strong>Honglan</strong> -a Caster who focuses on controlling the
            battlefield and doing hybrid (upfront and DoT) damage, Honglan is
            one of the best support-carries in the game. She slows the enemy
            team, allowing your team to skill faster and more often.
          </li>
        </ul>
        <h5>Fairy</h5>
        <p>
          The Fairy faction focuses on controlling the battlefield with CC
          abilities, and has a significant amount of strong casters and ranged
          attackers. They also boast some of the best supporters in the game
          that can be slotted into any team.
        </p>
        <p>Important characters from the faction:</p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="daphne" enablePopover />
          <EversoulCharacter mode="icon" slug="chloe" enablePopover />
          <EversoulCharacter mode="icon" slug="talia" enablePopover />
          <EversoulCharacter mode="icon" slug="vivienne" enablePopover />
        </div>
        <ul>
          <li>
            <strong>Daphne</strong> - fairy's second Defender, she reduces her
            team's damage by diverting it to herself, becomes invulnerable upon
            reaching 0 HP (once per battle) then takes all that damage and
            deletes the enemy team with her main skill. While she usually works
            better as a sub-tank, her utility cannot be denied.
          </li>
          <li>
            <strong>Talia</strong> - a Supporter who can heal and buff her
            allies, restore their mana so they can use their skills faster, and
            cleanse debuffs. On top of that, she can remove buffs from enemies,
            and her damage isn't too shabby either (if you use her Ultimate).
          </li>
          <li>
            <strong>Vivienne</strong> - a Caster who on top of having
            respectable damage, can provide a speed buff to her teammates,
            acting as a decent support even if she's not being used as a main
            DPS.
          </li>
          <li>
            <strong>Chloe </strong> - the classic Defender who can increase own
            DEF, CC enemies and taunt the whole enemy team. She also has a cheat
            death passive that allows her to heal as she reaches low HP (only
            once per battle). Basically the best pure tank in the game.
          </li>
        </ul>
        <h5>Undead</h5>
        <p>
          The Undead faction mainly focuses on damage over time effects and
          other debuffs, and tends to work better together than in mixed faction
          teams. Undead is well situated as the best raid faction, as they can
          deal respectable damage even at low investment.
        </p>
        <p>Important characters from the faction:</p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="violette" enablePopover />
          <EversoulCharacter mode="icon" slug="garnet" enablePopover />
          <EversoulCharacter mode="icon" slug="velanna" enablePopover />
          <EversoulCharacter mode="icon" slug="bryce" enablePopover />
          <EversoulCharacter mode="icon" slug="melfice" enablePopover />
        </div>
        <ul>
          <li>
            <strong>Violette</strong> - a Caster who can increase own ATK and
            damage output based on the amount of DoT effects on enemies. She is
            the original raid standard, doing amazing damage even at low
            investment when teamed up with other Undead faction Souls.
          </li>
          <li>
            <strong>Garnet</strong> - a support-based Ranger whose debuffs on
            the enemy are second to none. She increases your team's damage
            significantly, especially in raids, while also having respectable
            damage potential herself.
          </li>
          <li>
            <strong>Velanna</strong> - The first undead unit released after
            launch - Velanna manages to stay relevant till date due to her
            strong DPS stacking on enemies inflicted with Bleed, making her the
            best unit to pair with Ayame, as you can do a combo of Ayame Main →
            Vel Main → Vel Ult to eradicate most if not all enemies in one go.
            Outside of DPS, she has respectable tanking ability as offtank being
            a frontliner DPS and can help take load off main tanks in
            situations.
          </li>
          <li>
            <strong>Bryce and Melfice</strong> - The second pair of Twin Units
            introduced in game, Bryce and Melfice are very strong duo that not
            only excel when paired together, but also work very well solo unlike
            Mica and Seeha, The duo shines in raid content especially, landing
            them in best DPS teams more often than not.
          </li>
        </ul>
        <h5>Angel</h5>
        <p>
          The Angel faction is a special faction in Eversoul - not only can they
          only be countered by the Demon faction, but they can be placed in any
          team and act as one of the faction with the highest amount in that
          team for faction bonus. The downside to Angel faction is that they are
          hard to pull and ascend, making them a big investment to build.
        </p>
        <p>Available characters:</p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="yuria" enablePopover />
          <EversoulCharacter mode="icon" slug="claudia" enablePopover />
          <EversoulCharacter mode="icon" slug="adrianne" enablePopover />
        </div>
        <ul>
          <li>
            <strong>Yuria</strong> - The Defacto Queen of game, her highness
            Yuria is unmatched in her skillset and utility, despite not dealing
            a single point of damage throughout the fights, she is an invaluable
            asset due to her healing and buffs, and is especially beneficial in
            raids for her faction damage boost, which is unique to her and is
            able to boost your team’s raid damage to new heights.
          </li>
          <li>
            <strong>Claudia</strong> - a Caster whose AoE damage is second to
            none. When well invested, she can usually delete the entire enemy
            team, as long as your frontliners can keep her alive until she can
            use her main and Ultimate skills.
          </li>
          <li>
            <strong>Adrianne</strong> - a Defender who can heal allies, CC
            enemies and disrupt enemy formation by pulling important targets.
            She also gets stronger the more enemies surround her.
          </li>
        </ul>
        <h5>Demon</h5>
        <p>
          The Demon faction is another special faction in Eversoul - similar to
          Angel, they are only countered by Angel and can be placed into any
          team. However, their faction bonus differs in that it does not add to
          other faction bonuses, but brings its own more offensive-oriented
          bonus. They also have the same downside, as they are hard to pull and
          ascend.
        </p>
        <p>Available characters:</p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="ayame" enablePopover />
          <EversoulCharacter mode="icon" slug="lizelotte" enablePopover />
          <EversoulCharacter mode="icon" slug="eve" enablePopover />
        </div>
        <ul>
          <li>
            <strong>Ayame</strong> - a Caster who features one of the highest
            damage multipliers in-game and true to it, she deals massive DPS in
            AoE scenarios, but that’s not all as Ayame has plethora of other
            special skills including CC, debuffs, and increased damage enemies
            receive. As previously mentioned, her AoE bleed allows her to
            synergize with Velanna insanely well allowing the duo to wipe most
            enemy teams with their mains and ult combo.
          </li>
          <li>
            <strong>Lizelotte</strong> - a Ranger who can double the effect of
            most buffs affecting her, and has guaranteed crits against enemies
            that are isolated from their team (or have no team). Her kit makes
            her a top tier raid unit and with every new unit her value increases
            as more buffs for her to abuse.
          </li>
          <li>
            <strong>Eve</strong> - the First anniversary unit, Eve was the first
            unit featuring dual mode kit allowing her to function as both Main
            DPS and Pure support. While there are few other DPS better than her,
            only Yuria comes close to the sheer utility Eve provides to her
            teammates, fortunately both can be used together for amazing synergy
            and buffs to your team. Lizelotte’s best friend.
          </li>
        </ul>
        <h5>Chaos</h5>
        <p>
          The first faction introduced by game post launch – Chaos faction came
          to game in May 2024 and is aimed to be used by Variants of Existing
          characters. Like Angel and Demon factions, the units from these
          factions are harder to acquire and ascend (With addition of Chaos
          Faction units being restricted to their Pick Up Banners and Erika
          Alchemy as mode of obtaining only).
        </p>
        <p>Available characters:</p>
        <div className="employee-container for-nikke">
          <EversoulCharacter
            mode="icon"
            slug="catherine-radiance"
            enablePopover
          />
        </div>
        <ul>
          <li>
            <strong>Catherine (Radiance)</strong> - The First Chaos as well as
            Awakened Unit in-game, Catherine (Radiance) is another Dual Kit unit
            like Eve allowing her to function as DPS or as a Support. She
            similarly excels in both aspects, though she positively shines as a
            Support.
          </li>
        </ul>
        <p>
          On the image below you can see how the factions counter each other:
        </p>
        <StaticImage
          src="../../../images/eversoul/generic/team_counters.webp"
          alt="Guide"
        />
        <SectionHeader title="Rarity tiers" />
        <p>
          Each character that you can obtain in the game belongs to one of three
          rarity tiers: common, rare and epic.
        </p>
        <ul>
          <li>
            <strong>Common characters (green - max level is 40)</strong> will be
            rarely used even at the start of the game and will be discarded for
            a currency that you can use in a one of the shops,
          </li>
          <li>
            <strong>Rare characters (blue - max level is 60)</strong> will be
            used in the early game, but Epic characters will soon start
            replacing them. Still, Rare characters have an important function -
            they can be upgraded to Epic rarity (and higher) and then be used as
            fodder to upgrade the rarity of your Epic characters,
          </li>
          <li>
            <strong>Epic characters (purple - max level is 100)</strong> will
            form the core of your team once you obtain them, but getting a
            single copy of them isn't enough.
          </li>
        </ul>
        <p>
          In idle games, the starter rarities are only the beginning. While
          their names were not revealed yet, we know what colors they will have:
          orange, pink, and blue/purple gradient.
        </p>
        <p>
          To reach the higher rarities, you will need to raise Rare characters
          to Epic and use them as fodder, but also get dupes for the Epic
          characters you actually plan to use. We prepared a guide that explains
          how to use this system:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Ascension guide"
            link="/eversoul/guides/ascension"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_ascension.png"
                alt="Ascension guide"
              />
            }
          />
        </Row>
        <SectionHeader title="Team building" />
        <p>
          The team in Eversoul consist of 5 characters and similar to other
          games, you will obtain additional stats when you use more characters
          from the same faction.
        </p>
        <StaticImage
          src="../../../images/eversoul/generic/team_bonuses.webp"
          alt="Guide"
        />
        <ul>
          <li>
            Have 3 characters from the same faction in the team - +10% ATK and
            +10% HP,
          </li>
          <li>
            Have 5 characters each from different faction in the team - +10% ATK
            and +10% HP,
          </li>
          <li>
            Have 3 characters from the same faction in the team and 2 from a
            different faction - +15% ATK and +15% HP,
          </li>
          <li>
            Have 4 characters from the same faction in the team - +20% ATK and
            +20% HP,
          </li>
          <li>
            Have 5 characters from the same faction in the team - +25% ATK and
            +25% HP.
          </li>
        </ul>
        <p>
          <strong>Angel, Demon and Chaos</strong> factions break the above rules
          though. If you add an <strong>Angel</strong> faction character to your
          team, it will act as if it belongs to the faction you have most
          characters of in the team. For example, if you have 3x Undead, 1x
          Fairy and 1x Angel, you will get the 4 faction bonus as the Angel
          character will be treated as Undead.
        </p>
        <p>
          As for the <strong>Demon</strong> faction - they don't interact with
          other factions, but provide bonuses based on the number of Demon
          characters in the team:
        </p>
        <ul>
          <li>1x Demon - +15% ATK</li>
          <li>2x Demon - +15% ATK, +10% Crit chance</li>
          <li>3x Demon - +15% ATK, +10% Crit chance, +20% Crit dmg</li>
          <li>4x Demon - +20% ATK, +15% Crit chance, +25% Crit dmg</li>
          <li>5x Demon - +25% ATK, +20% Crit chance, +30% Crit dmg</li>
        </ul>
        <p>
          As for the <strong>Chaos</strong> - they don't interact with other
          factions, but provide bonuses based on the number of Chaos characters
          in the team, that is 3% Increase in HP/ATK/DEF for each Chaotic soul.
        </p>
        <p>
          But what's different from other idle games is that in Eversoul you can
          also set a Formation and depending on your choice, you will get
          different bonus stats.
        </p>
        <StaticImage
          src="../../../images/eversoul/generic/guide_formation.jpg"
          alt="Guide"
        />
        <p>There are 4 formations you can choose:</p>
        <ul>
          <li>
            3 back + 2 front - front characters get +9% DEF and HP, and back
            characters get +6% ATK
          </li>
          <li>
            2 back + 3 front - front characters get +6% DEF and HP, and back
            characters get +9% ATK
          </li>
          <li>
            4 back + 1 front - front character gets +16% DEF and HP, and back
            characters get +4% ATK
          </li>
          <li>
            1 back + 4 front - front characters get +4% DEF and HP, and back
            character gets +16% ATK
          </li>
        </ul>
        <Alert variant="primary">
          <Alert.Heading>Important information!</Alert.Heading>
          <p>
            Still, that's only the theory that you should be aware of, but in{' '}
            <strong>
              reality you won't build your team to obtain the faction buffs
            </strong>{' '}
            - especially in the early. Why? Because you will be{' '}
            <strong>limited by the fodder mentioned earlier</strong>. And the
            more characters from one faction that you will use, the slower your
            progress will become.
          </p>
        </Alert>
        <p>So what should you do to progress faster?</p>
        <ul>
          <li>
            <strong>Pick 1 character each from the 4 main factions</strong>{' '}
            (Humans, Beasts, Fairy and Undead) that you have most dupes of,
          </li>
          <li>
            For the 5th spot, pick a second character from the faction you have
            most dupes and fodder,
          </li>
          <li>
            Throw the characters you actually want to use in your team in the
            Synchro Device - this will allow you to level them past their level
            cap and still make them usable,
          </li>
          <ul>
            <li>
              Just do note that this will work when the difference in tier is is
              1-2 tiers. When it's bigger, the stat bonus you get from Ascending
              up a tier will be too big and you will have to adjust your plan.
            </li>
          </ul>
        </ul>
        <p>
          This will allow you to spread your Fodder nicely and by doing so{' '}
          <strong>progress at a much faster pace</strong>. And at the same time,
          you will be able to build your team to make use of the Faction
          bonuses.
        </p>
        <p>For a dedicated early-game team building guide go here:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Team building"
            link="/eversoul/guides/team-building"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_team.png"
                alt="Team building"
              />
            }
          />
        </Row>
        <SectionHeader title="Tier list" />
        <p>Check our tier list for the early game!</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tier list"
            link="/eversoul/tier-list"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_reroll.jpg"
                alt="Tier list"
              />
            }
          />
        </Row>
        <SectionHeader title="Early progression" />
        <p>
          <strong>
            Tutorial is the first thing that awaits you in Eversoul
          </strong>
          . The game will teach you how to setup formation, use Active and
          Ultimate skills, how to level a character and other things. You will
          obtain Linzy midway though the tutorial. If you decide to skip all
          cutscenes, the tutorial should take you around 15-20 minutes or so.
        </p>
        <p>
          At the end of the tutorial, you will unlock the Summoning feature and
          obtain the <strong>Infinite Newbie Ticket</strong>. As the name
          suggest, you will be able to reroll the ticket as many times as you
          want. After doing a pull using the ticket, you will obtain 10
          characters and only one of them will be Epic. Still, this is enough to
          get the character you want, so don't be afraid to roll as long as you
          need. After accepting the pull you made, the game will progress.
        </p>
        <p>
          Continue progressing through the story and by doing so, you will
          unlock additional features.
        </p>
        <p>For more tricks and tips, check this guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tips and tricks"
            link="/eversoul/guides/tips-and-tricks"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_tips.png"
                alt="Tips and trick"
              />
            }
          />
        </Row>
        <SectionHeader title="More information" />
        <p>
          If you want to learn more about the the game, check our massive
          beginner guide guide, available here:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Beginner's guide"
            link="/eversoul/guides/beginner-guide"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_beginner.jpg"
                alt="Beginner's guide"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesIntro;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to the game | Eversoul | Prydwen Institute"
    description="Basic information about Eversoul."
    game="eversoul"
  />
);
